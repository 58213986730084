<template>
  <div class="register padding-x">
    <Preloader :loading="isBoughtTicketsLoading" />
    <div
      v-if="!isBoughtTicketsLoading && boughtTickets"
      class="register__panel"
    >
      <b>Redeemed tickets &nbsp;&nbsp;&nbsp; x{{ boughtTickets.length }}</b>
      <dl>
        <dd
          v-for="ticket in boughtTickets"
          :key="ticket.id"
          class="register__panel-item"
        >
          {{ ticket.first_name }} {{ ticket.last_name }}
        </dd>
      </dl>
    </div>

    <div class="register__wrapper">
      <Heading type="h3"> Register attendees</Heading>
      <div v-show="!regSuccess" class="my-6">
        {{ ticket.title }} - {{ ticket.limit - claimed }} ticket{{
          ticket.limit - claimed > 1 ? "s" : ""
        }}
        available
        <br />
        <Link
          v-if="isTicketSponsors"
          :link="`/${$route.params.id}/exhibitor-kit/${userCodesToken}`"
        >
          Click to return to exhibitor kit page with codes
        </Link>
        <Link
          v-else-if="isTicketSpeakers"
          :link="`/${$route.params.id}/speaker-kit/${userCodesToken}`"
        >
          Click to return to speaker kit page with codes
        </Link>
        <Link v-else :link="`/${$route.params.id}/tickets/${userCodesToken}`">
          Click to return to codes page
        </Link>
      </div>

      <div v-if="regSuccess" class="register__success">
        Thank you! <br />
        You have successfully registered all available codes.<br />
        <!--        <Link link="/login">Go to login</Link>-->
        <br />
        <Link
          v-if="isTicketSponsors"
          :link="`/${$route.params.id}/exhibitor-kit/${userCodesToken}`"
        >
          Exhibitor kit with my codes
        </Link>
        <Link
          v-else-if="isTicketSpeakers"
          :link="`/${$route.params.id}/speaker-kit/${userCodesToken}`"
        >
          Speaker kit with my codes
        </Link>
        <!-- <Link v-else :link="`${$route.params.id}/tickets/${userCodesToken}`">My codes</Link>
        <br /> -->
        <Link
          v-if="ticket.limit === 1"
          :link="`/${$route.params.id}/ticket/${successId}`"
        >
          Click to see ticket
        </Link>
      </div>

      <form v-else @submit.prevent="submitRegistration">
        <transition name="fade">
          <div v-if="sentForm" class="register__tip">
            {{ claimed }} ticket{{ claimed > 1 ? "s" : "" }}
            redeemed successfully (we will send a notification to email
            provided). You may now continue registering attendees.
          </div>
        </transition>
        <Input
          v-for="(field, name) in form"
          :key="name"
          v-model="field.value"
          :error="field.error"
          :name="name"
          :label="field.label"
          :is-multiline="field.isMultiline"
          :disabled="field.disabled"
          :rows="field.rows"
          type="text"
          @input="clearError(name, form)"
          @blur="validateField(name, form)"
        />

        <input
          type="checkbox"
          id="agreeToTerms"
          v-model="agreeToTerms"
          @input="termsError = null"
        />
        <label for="agreeToTerms">
          I have read and agree to
          <Link
            link="/attendee-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            attendee privacy policy
          </Link>
        </label>
        <br />
        <input type="checkbox" id="optin" v-model="optin" />
        <label for="optin">
          I want to receive communications from sponsors including ticket
          upgrades, workshop information, and other promotional materials
        </label>
        <transition name="fade">
          <div v-show="termsError" class="input__error">
            {{ termsError }}
          </div>
        </transition>
        <transition name="fade">
          <Error v-if="regError" class="register__error">
            <template #heading
              >{{ regError || "Sorry, something went wrong" }}
            </template>
            <template #body>
              Please try again or contact us at
              <a href="mailto:info@arctic-con.com" class="error__link">
                info@arctic-con.com
              </a>
            </template>
          </Error>
        </transition>
        <Preloader :loading="loading" />
        <Button v-show="!loading" fullWidth class="mt-5"> send </Button>
        <span class="register__limit">
          {{ claimed }}/{{ ticket.limit }} codes
        </span>
      </form>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import Input from "@/components/common/Input";
import Link from "@/components/common/Link";
import Button from "@/components/common/Button";
import Error from "@/components/common/Error";
import {
  validateField,
  validateForm,
  clearError,
  clearForm,
} from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import { mapActions, mapState } from "vuex";

export default {
  name: "RegisterAttendees",
  props: {},
  components: {
    Heading,
    Preloader,
    Input,
    Link,
    Button,
    Error,
  },
  data: () => ({
    loading: false,
    ticket: {},
    isTicketSponsors: false,
    isTicketSpeakers: false,
    isBoughtTicketsLoading: false,
    boughtTickets: null,
    claimed: 0,
    sentForm: null,
    regError: null,
    regSuccess: false,
    successId: null,
    userCodesToken: null,
    form: {
      first_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "First Name",
      },
      last_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Last Name",
      },
      organization: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Organization",
      },
      job_title: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Job Title",
      },
      phone: {
        value: "",
        error: "",
        rules: ["required", "phone"],
        label: "Phone",
      },
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
        label: "Email",
      },
    },
    agreeToTerms: false,
    termsError: null,
    optin: false,
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.getTickets();
    if (JSON.parse(localStorage.getItem("chosen-sponsor-code"))) {
      this.ticket = JSON.parse(localStorage.getItem("chosen-sponsor-code"));
      this.claimed = JSON.parse(
        localStorage.getItem("chosen-sponsor-code")
      ).claimed;
      this.isTicketSponsors = true;
    } else if (JSON.parse(localStorage.getItem("chosen-speaker-code"))) {
      this.ticket = JSON.parse(localStorage.getItem("chosen-speaker-code"));
      this.claimed = JSON.parse(
        localStorage.getItem("chosen-speaker-code")
      ).claimed;
      this.isTicketSpeakers = true;
    } else {
      this.ticket = JSON.parse(localStorage.getItem("chosen-code"));
      this.claimed = JSON.parse(localStorage.getItem("chosen-code")).claimed;
    }
    this.userCodesToken = JSON.parse(localStorage.getItem("codes-token"));
  },
  methods: {
    transformForm,
    validateField,
    validateForm,
    clearError,
    clearForm,
    ...mapActions(["POST", "GET"]),
    getTickets() {
      this.isBoughtTicketsLoading = true;
      return this.GET({
        route: `ticketing/public/event/${this.$route.params.id}/code/${this.$route.params.code}/tickets`,
      })
        .then(({ data }) => {
          console.log("getTickets", data);
          this.boughtTickets = data;
          this.isBoughtTicketsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isBoughtTicketsLoading = false;
        });
    },
    submitRegistration() {
      this.regError = null;
      const isValid = validateForm(this.form);
      if (!this.agreeToTerms)
        this.termsError = "Please read and agree to attendee privacy policy";
      if (!isValid || !this.agreeToTerms) return;

      this.loading = true;
      const formData = this.transformForm(this.form);
      formData.code = this.$route.params.code;
      formData.optin = this.optin;

      this.POST({
        route: `/ticketing/public/event/${this.$route.params.id}/code/${formData.code}/redeem`,
        data: formData,
      })
        .then(({ data }) => {
          this.successId = data.id;
          this.claimed = this.claimed + 1;
          if (this.claimed === this.ticket.limit) this.regSuccess = true;
          this.getTickets();
          this.sentForm = formData;
          this.clearForm(this.form);
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 500)
            this.regError = "Sorry, something went wrong";
          this.regError = err.response.data.msg;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.register {
  padding-top: 106px;
  display: flex;
  flex-direction: column-reverse;

  &__panel {
    margin-top: 40px;
    padding: 2% 0 0 20%;
    background-color: $light-gray;

    &-item {
      position: relative;
      margin: 10px 0;

      &::before {
        content: "";
        position: absolute;
        bottom: 5px;
        left: -15px;
        height: 25px;
        border-left: 2px solid #1d1c1c;
      }
    }
  }

  &__wrapper {
    width: 55%;
    margin: 0 auto;
  }

  &__limit {
    float: right;
  }

  &__tip {
    color: $dark-gray;
    font-size: $sarabun;
    font-weight: $sarabunSemibold;
    text-align: center;
  }

  &__error {
    text-align: center;
  }

  &__success {
    margin-top: 10%;
    font-size: 1.3rem;
  }
}

@media (min-width: $media-sm) {
  .register {
    flex-direction: row;

    &__panel {
      background-color: transparent;
      padding: 0;
      margin-top: 106px;
      display: flex;
      flex-direction: column;
      max-width: 25%;
      word-break: break-word;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 100%;
        background-color: $light-gray;
        z-index: -1;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .register {
    &__panel {
      max-width: 90%;
      &::after {
        width: 35%;
      }
    }
  }
}
</style>
