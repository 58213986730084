<template>
  <RegisterAttendees />
</template>

<script>
import RegisterAttendees from "@/components/ticketsRegistration/RegisterAttendees";

export default {
  name: "RegisterAttendeesPage",
  props: {},
  components: {
    RegisterAttendees,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
